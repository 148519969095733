<button (click)="open($event)" *ngIf="!value">{{ 'Select Date' | translate }}</button>
<button (click)="open($event)" *ngIf="value">{{dateTimeService.format(value, 'MMM d, yyyy')}}</button> <button
  *ngIf="value && useTime" (click)="open($event)">{{dateTimeService.format(value, 'hh:mm a')}}</button>
<ion-modal [initialBreakpoint]="useTime ? 1 : 0.7" [breakpoints]="useTime ? [0, 1] : [0, 0.7, 1]">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="close()">{{ 'Close' | translate }}</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="select()">{{ 'Select' | translate }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="pt-2">
      <ion-datetime [value]="_date" (ionChange)="changed($event)" presentation="date" size="cover" #datetimeDate [max]="maxDate | date:'yyyy-MM-ddTHH:mm:ss'">
      </ion-datetime>
      <ion-datetime [value]="_date" (ionChange)="changed($event)" presentation="time" [max]="maxDate | date:'yyyy-MM-ddTHH:mm:ss'"
        minuteValues="0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59" size="cover" #datetimeTime *ngIf="useTime"></ion-datetime>
      <ion-toolbar *ngIf="showClearButton" color="light" class="border-top">
        <ion-buttons>
          <ion-button color="danger" (click)="clear()">{{ 'Clear' | translate }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-content>
  </ng-template>
</ion-modal>