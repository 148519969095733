import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { IonDatetime, IonModal } from '@ionic/angular';
import { DateTimeService } from 'src/app/services/date-time.service';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {

  @ViewChild(IonModal) modal!: IonModal;

  @Input() value?: Date = new Date();
  @Output() valueChange = new EventEmitter<Date>();

  @Input() maxDate?: Date;

  @Input() useTime: boolean = true;

  @Input() showClearButton: boolean = false;

  @Output() onChange = new EventEmitter<Date | boolean>();

  @ViewChild('datetimeDate') dtDate!: IonDatetime;
  @ViewChild('datetimeTime') dtTime!: IonDatetime;

  _date?: string;
  _time?: string;

  constructor(public dateTimeService: DateTimeService) { }

  ngOnInit() {
    if (this.value) {
      this._date = this.dateTimeService.format(this.value);
    }
  }

  async open(event: Event) {
    event.preventDefault();
    await this.modal.present();
  }

  changed(event: any) {
    this._date = event.detail.value;
  }

  async close() {
    await this.modal.dismiss(null, 'close');
    if (this.value) {
      this._date = this.dateTimeService.format(this.value);
    }
  }

  select() {
    this.modal.dismiss(null, 'select');
    this.value = new Date(this._date!);
    this.onChange.emit(this.value);
    this.valueChange.emit(this.value);
  }

  clear() {
    console.log('clear')
    this.dtDate.reset();
    if (this.useTime) 
      this.dtTime.reset();

    this.value = undefined;
    this._date = undefined;
    this.onChange.emit(this.value);
    this.valueChange.emit(this.value);
    this.close();
  }
}