import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {

    constructor() { }

    // Convert a UTC string to local time
    toLocal(utc: string): Date {
        const pattern: RegExp = /\.\d{3}Z$/;
        if (!pattern.test(utc)) {
            utc = utc + '.000Z'; // Ensure the string has a UTC format
        }

        // Create the Date object in UTC and convert it to local time
        const dateUtc = new Date(utc);
        return new Date(dateUtc.getTime() + dateUtc.getTimezoneOffset() * 60000);
    }

    // Convert a local date to UTC
    toUtc(local: Date): string {
        const utcDate = new Date(local.getTime() - local.getTimezoneOffset() * 60000);
        return utcDate.toISOString(); // Return the date in UTC ISO format
    }

    // Get the browser's timezone
    getTimeZone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    format(date: Date | string | undefined | null, formatStr?: string): string {
        if (!date) return '';
        if (typeof date === 'string') {
            date = new Date(date);
        }

        // Default format to 'MM/dd/yyyy HH:mm:ss' if none is provided
        const defaultFormat = 'MM/dd/yyyy HH:mm:ss';
        formatStr = formatStr || defaultFormat;

        // Mapping for date formatting tokens
        const map: Record<string, string> = {
            'MMM': this.getMonthName(date, 'short'),  // Short month name (e.g., 'Jan')
            'MMMM': this.getMonthName(date, 'long'),  // Full month name (e.g., 'January')
            'MM': ('0' + (date!.getMonth() + 1)).slice(-2), // Month (2 digits)
            'M': (date!.getMonth() + 1).toString(),  // Month (1 digit)
            'dd': ('0' + date!.getDate()).slice(-2), // Day (2 digits)
            'd': date!.getDate().toString(),         // Day (1 digit)
            'yyyy': date!.getFullYear().toString(),  // Full year
            'yy': date!.getFullYear().toString().slice(-2), // Year (last 2 digits)
            'HH': ('0' + date!.getHours()).slice(-2), // 24-hour format
            'hh': ('0' + this.get12HourFormat(date!)).slice(-2), // 12-hour format
            'mm': ('0' + date!.getMinutes()).slice(-2), // Minutes
            'ss': ('0' + date!.getSeconds()).slice(-2), // Seconds
            'a': this.getAmPm(date!)  // AM/PM marker
        };

        // Replace tokens in the format string with actual date values
        return formatStr.replace(/MMM|MMMM|MM|M|dd|d|yyyy|yy|HH|hh|mm|ss|a/g, (matched) => map[matched] || '');
    }

    // Helper method to get the month name
    private getMonthName(date: Date | null, format: 'short' | 'long'): string {
        return date ? date.toLocaleString('en-US', { month: format }) : '';
    }

    // Helper method to convert to 12-hour format
    private get12HourFormat(date: Date): number {
        const hours = date.getHours();
        return hours % 12 || 12; // Convert 0 to 12 (midnight or noon)
    }

    // Helper method to get AM or PM
    private getAmPm(date: Date): string {
        return date.getHours() >= 12 ? 'PM' : 'AM';
    }
}
