import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ApiService } from '../services/api.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  return inject(ApiService).canActivate();
};

export const IsLoggedOutGuard: CanActivateFn = async  (route, state) => {
  return await inject(ApiService).isLoggedOut();
};
