<ion-menu side="end" contentId="main-content">
  <ion-content>
    <ion-list class="mt-1">
      <ion-item button detail="false" (click)="navigateToProfile()">
        <ion-label>{{"Profile.Title" | translate}}</ion-label>
      </ion-item>
      <ion-item button detail="false" (click)="navigateToParkingRules()">
        <ion-label>{{"ParkingRules.Title" | translate}}</ion-label>
      </ion-item>
      <ion-item button detail="false" (click)="navigateToChangePassword()">
        <ion-label>{{"ChangePassword.Label" | translate}}</ion-label>
      </ion-item>
      <ion-item button detail="false" (click)="signout()"> 
        <ion-label color="danger">{{"SignOut.Label" | translate}}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>  
</ion-menu>