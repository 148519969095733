import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response';
import { TokenModel } from '../models/token-model';
import { PreferenceService } from './preference.service';
import { Router } from '@angular/router';
import { SemaphoreService } from './semaphore.service';
import { jwtDecode } from "jwt-decode";

const TOKEN_KEY = 'VigilantPass.Patrol.Token';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    currentToken?: TokenModel;
    isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

    constructor(private http: HttpClient, private preferenceService: PreferenceService, private router: Router, private semaphoreService: SemaphoreService) {
        this.loadToken();
    }

    async loadToken() {
        const token = await this.preferenceService.get(TOKEN_KEY);
        if (token) {
            this.currentToken = JSON.parse(token);
            this.isAuthenticated.next(true);
        } else {
            this.isAuthenticated.next(false);
        }
    }

    async canActivate() {
        let isAuthenticated = this.isAuthenticated.getValue();

        let attempts = 0;
        while (isAuthenticated == null && attempts < 10) {
            console.log(`canActivate: isAuthenticated is null. attempts ${attempts}`);
            await new Promise(resolve => setTimeout(resolve, 100));
            isAuthenticated = this.isAuthenticated.getValue();
            attempts++;
        }

        if (isAuthenticated) {
            return true;
        } else {
            this.router.navigateByUrl('/login')
            return false;
        }
    }

    async isLoggedOut() {
        let isAuthenticated = this.isAuthenticated.getValue();

        let attempts = 0;
        while (isAuthenticated == null && attempts < 10) {
            console.log(`canActivate isLoggedOut: isAuthenticated is null. attempts ${attempts}`);
            await new Promise(resolve => setTimeout(resolve, 100));
            isAuthenticated = this.isAuthenticated.getValue();
            attempts++;
        }

        if (isAuthenticated) {
            this.router.navigateByUrl('/')
            return false;
        } else {
            return true;
        }
    }

    async get<T>(requestUri: string) {
        return await this.execute<T>(requestUri, HttpMethod.GET);
    }

    async post<T>(requestUri: string, body: any) {
        return await this.execute<T>(requestUri, HttpMethod.POST, body);
    }

    async put<T>(requestUri: string, body: any) {
        return await this.execute<T>(requestUri, HttpMethod.PUT, body);
    }

    async delete<T>(requestUri: string) {
        return await this.execute<T>(requestUri, HttpMethod.DELETE);
    }

    async upload<T>(requestUri: string, blob: Blob, fileName: string) {
        const formData = new FormData();
        formData.append('file', blob, fileName);
        return await this.execute<T>(requestUri, HttpMethod.UPLOAD, formData);
    }

    private async execute<T>(requestUri: string, httpMethod: HttpMethod, body: any = undefined): Promise<ApiResponse<T>> {

        var headers = new HttpHeaders().set('Content-Type', 'application/json');

        if (httpMethod == HttpMethod.UPLOAD) {
            headers = headers.delete('Content-Type');
        }

        if (this.currentToken) {
            var accessToken = this.currentToken.AccessToken;

            if (this.isTokenExpired(accessToken)) {
                if (await this.tryRefreshTokenAsync(this.currentToken.AccessToken)) {
                    accessToken = this.currentToken.AccessToken;
                }
            }

            headers = headers.set('Authorization', `Bearer ${accessToken}`);
        }

        var httpOptions = { headers };

        try {

            switch (httpMethod) {
                case HttpMethod.GET:
                    {
                        var observableResponse = this.http.get<ApiResponse<T>>(`${environment.apiUrl}/api/${requestUri}`, httpOptions);
                        return await lastValueFrom(observableResponse);
                    }
                case HttpMethod.POST:
                case HttpMethod.UPLOAD:
                    {
                        var observableResponse = this.http.post<ApiResponse<T>>(`${environment.apiUrl}/api/${requestUri}`, body, httpOptions);
                        return await lastValueFrom(observableResponse);
                    }
                case HttpMethod.PUT:
                    {
                        var observableResponse = this.http.put<ApiResponse<T>>(`${environment.apiUrl}/api/${requestUri}`, body, httpOptions);
                        return await lastValueFrom(observableResponse);
                    }
                case HttpMethod.DELETE:
                    {
                        var observableResponse = this.http.delete<ApiResponse<T>>(`${environment.apiUrl}/api/${requestUri}`, httpOptions);
                        return await lastValueFrom(observableResponse);
                    }
                default:
                    throw new Error(`Utility code not implemented for this Http method ${httpMethod}`);
            }
        } catch (error: any) {
            if (error.status === 401) {
                if (this.currentToken) {
                    if (await this.tryRefreshTokenAsync(this.currentToken.AccessToken)) {
                        return this.execute<T>(requestUri, httpMethod, body);
                    }
                }
                this.logout();
            }

            const errorResponse: ApiResponse<T> = {
                Success: false,
                Code: error.statusText,
                Message: error.message,
                Data: undefined!
            };

            return errorResponse;
        }
    }

    isTokenExpired(token: string): boolean {
        if (!token) {
            return true;
        }

        const decoded: any = jwtDecode(token);
        if (!decoded.exp) {
            return true;
        }

        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decoded.exp);

        return expirationDate < new Date();
    }

    private async tryRefreshTokenAsync(oldAccessToken: string): Promise<boolean> {
        await this.semaphoreService.acquire();
        try {
            const token = await this.preferenceService.get(TOKEN_KEY);

            if (!token) {
                return false;
            }

            if (oldAccessToken !== JSON.parse(token).AccessToken) {
                return true;
            }

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };

            const observable = this.http.post<ApiResponse<TokenModel>>(`${environment.apiUrl}/api/auth/refreshtoken`, token, httpOptions);
            const response = await lastValueFrom(observable) as ApiResponse<TokenModel>;

            if (!response.Success) {
                return false;
            }

            this.login(response.Data)

            return true;
        } catch {
            return false;
        } finally {
            this.semaphoreService.release();
        }
    }

    async login(token: TokenModel) {
        this.currentToken = token;
        await this.preferenceService.set(TOKEN_KEY, JSON.stringify(token));
        this.isAuthenticated.next(true);
    }

    async logout() {
        this.currentToken = undefined;
        await this.preferenceService.remove(TOKEN_KEY);
        this.isAuthenticated.next(false);
        this.router.navigateByUrl('login', { replaceUrl: true });
    }
}

export const enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    UPLOAD = 'UPLOAD'
}
