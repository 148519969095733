import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { FormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
import { MaskitoDirective } from '@maskito/angular';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    LazyLoadImageModule,
    MaskitoDirective
  ],
  providers: [LazyLoadImageModule, PhotoViewer],
  declarations: [MenuComponent, DateTimePickerComponent],
  exports: [TranslateModule, MenuComponent, MaskitoDirective, DateTimePickerComponent]
})
export class SharedModule { }
